'use client';

import Image from 'next/image';
import React, { useEffect } from 'react';
import styles from './LoadingEffect.module.css';
const LoadingAnimation: React.FC = () => {
  useEffect(() => {
    const symbols = Array.from(document.querySelectorAll(`.${styles.symbol}`));
    const spinner = document.querySelector(`.${styles.spinner}`) as HTMLElement;
    const logo = document.querySelector(`.${styles.logo}`) as HTMLElement;
    let positions = [{
      top: '25%',
      left: '25%'
    }, {
      top: '25%',
      left: '75%'
    }, {
      top: '75%',
      left: '75%'
    }, {
      top: '75%',
      left: '25%'
    }];
    let rotationCount = 0;
    const rotateSymbols = () => {
      // Move each symbol to the next position
      symbols.forEach((symbol, i) => {
        const newIndex = (i + 1) % symbols.length;
        const position = positions[newIndex];
        (symbol as HTMLElement).style.top = position.top;
        (symbol as HTMLElement).style.left = position.left;
      });

      // Shift positions array to the right
      const firstPosition = positions.shift();
      if (firstPosition) {
        positions.push(firstPosition);
      }
      rotationCount++;
      logo.classList.add(styles.hidden); // Ensure logo is hidden during rotation

      if (rotationCount === 3) {
        // Trigger the shrink animation
        setTimeout(() => {
          spinner.classList.add(styles.shrink);
          setTimeout(() => {
            // Hide symbols and show logo
            symbols.forEach(symbol => {
              (symbol as HTMLElement).classList.add(styles.hidden);
              (symbol as HTMLElement).classList.remove(styles.visible);
            });
            logo.classList.remove(styles.hidden);
            logo.classList.add(styles.visible);
            setTimeout(() => {
              // Reset spinner size and visibility of elements
              spinner.classList.remove(styles.shrink);
              symbols.forEach(symbol => {
                (symbol as HTMLElement).classList.remove(styles.hidden);
                (symbol as HTMLElement).classList.add(styles.visible);
              });
              logo.classList.remove(styles.visible);
              logo.classList.add(styles.hidden);

              // Reset to initial settings 
              rotationCount = 0;
              clearInterval(interval);
              startRotation(); // Restart the rotation
            }, 500); // Time for the shrink animation to complete
          }, 200); // Time for the transition to complete
        }, 500); // Delay before starting the shrink animation
      }
    };
    const startRotation = () => {
      // Set interval to rotate symbols every 800ms
      interval = setInterval(() => {
        rotateSymbols();
      }, 800);
    };

    // Start the initial rotation
    let interval = setInterval(() => {
      rotateSymbols();
    }, 800);
    return () => clearInterval(interval);
  }, []);
  return <div id="overlay" className={styles.overlay} style={{
    display: 'flex'
  }} data-sentry-component="LoadingAnimation" data-sentry-source-file="LoadingEffect.tsx">
      <div className={styles.spinner}>
        <div className={`${styles.logo} ${styles.hidden}`}>
		  <Image src={'/logo/logo-white.svg'} alt='Company Logo' fill data-sentry-element="Image" data-sentry-source-file="LoadingEffect.tsx" />
        </div>
        <div className={`${styles.symbol} ${styles.symbol1}`}>
		  <Image src={'/resourcesIcon/amazon-white.svg'} alt='Amazon Logo' fill data-sentry-element="Image" data-sentry-source-file="LoadingEffect.tsx" />
        </div>
        <div className={`${styles.symbol} ${styles.symbol2}`}>
 		  <Image src={'/resourcesIcon/ebay-white.svg'} alt='eBay Logo' fill data-sentry-element="Image" data-sentry-source-file="LoadingEffect.tsx" />
        </div>
        <div className={`${styles.symbol} ${styles.symbol3}`}>
 		  <Image src={'/resourcesIcon/google-white.svg'} alt='Google Logo' fill data-sentry-element="Image" data-sentry-source-file="LoadingEffect.tsx" />
        </div>
        <div className={`${styles.symbol} ${styles.symbol4}`}>
		  <Image src={'/resourcesIcon/reddit-white.svg'} alt='Reddit Logo' fill data-sentry-element="Image" data-sentry-source-file="LoadingEffect.tsx" />
        </div>
      </div>
    </div>;
};
export default LoadingAnimation;